<div class="star">
	<ng-container *ngFor="let number of [1,2,3,4,5]">
		<ng-container *ngIf="rating>=number">
			<i class="las la-star"></i>
		</ng-container>
		<ng-container *ngIf="number>rating">
			<ng-container *ngIf="number - rating > 0 && 1 > number - rating ">
				<i class="las la-star-half-alt"></i>
			</ng-container>
			<ng-container *ngIf=" number>= rating+1">
				<i class="lar la-star"></i>
			</ng-container>
		</ng-container>
	</ng-container>
</div>