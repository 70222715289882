<ng-container *ngIf="loadername=='menu-left-menucategory'">
	<div class="menu-left-menucategory">
		<div class="loading-skeleton">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<ul>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="loadername=='menu-right-cartlist'">
	<div class="menu-left-menucategory">
		<div class="loading-skeleton">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<ul>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="loadername=='menu-center-menu-tab'">
	<div class="menu-left-menucategory">
		<div class="loading-skeleton">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<ul>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="loadername=='menu-center-menu-list'">
	<div class="menu-left-menucategory">
		<div class="loading-skeleton">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<ul>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
							<li>Lorem Ipsum</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>