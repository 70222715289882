import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SvgIconComponent,
    StarRatingComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SvgIconComponent,
    StarRatingComponent,
    LoaderComponent
  ]
})
export class ComponentModule { }
